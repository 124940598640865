import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import achievements from './modules/achievements';
import featuredachievements from './modules/featuredachievements';
import app from './modules/app';
import articles from './modules/articles';
import auth from './modules/auth';
import crmfilters from './modules/crmfilters';
import user from './modules/user';
import users from './modules/users';
import schools from './modules/schools';
import schoolclasses from './modules/schoolclasses';
import schoolhomework from './modules/schoolhomework';
import schoolclasslevels from './modules/schoolclasslevels';
import courses from './modules/courses';
import books from './modules/books';
import assignments from './modules/assignments';
import assignmenttypes from './modules/assignmenttypes';
import screeningassignments from './modules/screeningassignments';
import screeningassignmenttypes from './modules/screeningassignmenttypes';
import tooltips from './modules/tooltips';
import sections from './modules/sections';
import tasks from './modules/tasks';
import educations from './modules/educations';
import unilogin from './modules/unilogin';
import notifications from './modules/notifications';
import news from './modules/news';
import sectionrelations from './modules/sectionrelations';
import sitemap from './modules/sitemap';
import systemstats from './modules/systemstats';
import media from './modules/media';
import images from './modules/images';
import authors from './modules/authors';
import activity from './modules/activity';
import tags from './modules/tags';
import categories from './modules/categories';
import bundles from './modules/bundles';
import emaillogs from './modules/emaillogs';
import lix from './modules/lix';
import references from './modules/references';
import languages from './modules/languages';
import router from './modules/router';

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
	modules: {
		achievements,
		featuredachievements,
		app,
		articles,
		auth,
		crmfilters,
		user,
		users,
		schools,
		schoolclasses,
		schoolhomework,
		schoolclasslevels,
		courses,
		books,
		assignments,
		assignmenttypes,
		screeningassignments,
		screeningassignmenttypes,
		tooltips,
		sections,
		tasks,
		educations,
		unilogin,
		notifications,
		news,
		sectionrelations,
		sitemap,
		systemstats,
		media,
		images,
		authors,
		activity,
		tags,
		categories,
		bundles,
		emaillogs,
		lix,
		references,
		languages,
		router,
	},
	strict: debug,
});
